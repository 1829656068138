<template>
  <fieldset>
    <DocFieldsZuEgrn 
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
       @update:modelValue='$emit("update:modelValue", $event)'
    >
      <template #additional-fields>
        <DateField
          :modelValue="modelValue.reserveDate"
          @update:modelValue="update('reserveDate', $event, v$.reserveDate)"
          :errors="v$.reserveDate.$errors"
          data-qa="reservationLand"
        >
          <label class="label label--required">Срок резервирования земель</label>
        </DateField>
      </template>
    </DocFieldsZuEgrn>
  </fieldset>
</template>

<script>
import DocSection12 from "../../../models/documents/docSection12";
import DocFieldsZuEgrn from "./DocFieldsZuEgrn";
import DateField from "../../basic/form/DateField";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocSection12(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFieldsZuEgrn, DateField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      reserveDate: {
        required: helpers.withMessage("Заполните поле", required),
        minValue: helpers.withMessage("Срок резервирования земель должен быть не ранее даты документа", (value) => {
          return value >= data.value.docDate;
        }),
      }
    };

    const v$ = useValidator(data, rules);

    return { data, update, v$ };
  },
};
</script>